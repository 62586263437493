import ApplicationController from './application_controller';

export default class extends ApplicationController {
  afterReflex() { }

  connect() {
    super.connect();

    let itemsController = this;

    $('#warehouseSummaryModal').on('show.bs.modal', (event) => {
      itemsController.fetchWarehouseSummary(event.relatedTarget);
    })
  }

  fetchWarehouseSummary(target) {
    this.stimulate('ItemsReflex#warehouse_summary', target);
  }
}
