import toastr from 'toastr/toastr';
import ApplicationController from './application_controller';
import { reflexableSelectsHelper } from './mixins/reflexable_selects_helper';
import { translationHelper } from "./mixins/translation_helper";

export default class extends ApplicationController {
  afterReflex() { }

  connect() {
    super.connect();
    translationHelper(this);
    reflexableSelectsHelper(this);
  }

  renderNewComponentForm(event) {
    let formId = `component_form_${event.currentTarget.dataset.addButtonUuid}`;
    this.stimulate('Components#build_create_component_form', event.currentTarget).then(() => {
      this.initReflexableSelects();
      this.handleChangedItem();
      this.updateAuthenticityToken(formId);
    }).catch((response) => {
      console.error(response);
    });
  }

  renderEditComponentForm(event) {
    let formId = `component_form_${event.currentTarget.dataset.addButtonUuid}`;
    this.stimulate('Components#build_edit_component_form', event.currentTarget).then(() => {
      this.initReflexableSelects();
      this.handleChangedItem();
      this.updateAuthenticityToken(formId);
    }).catch((response) => {
      console.error(response);
    });
  }

  cancelEditForm(event) {
    let buildEditComponentFormId = event.currentTarget.dataset.parentComponentUuid;
    let buildEditComponentForm = document.getElementById(buildEditComponentFormId);
    buildEditComponentForm.innerHTML = "";
  }

  create(event) {
    let componentsController = this;
    let currentTarget = event.currentTarget;

    this.stimulate('Components#create', event.currentTarget).then(() => {
      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
      else {
        toastr.success(this.t('controllers.notices.created'));
        componentsController.refreshComponentsTree(currentTarget);
      }
    }).catch((_response) => {
      toastr.error(I18n.t('views.application.something_went_wrong'));
    });
  }

  update(event) {
    let componentsController = this;
    let currentTarget = event.currentTarget;

    this.stimulate('Components#update', event.currentTarget).then(() => {
      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
      else {
        toastr.success(this.t('controllers.notices.updated_successfully'));
        componentsController.refreshComponentsTree(currentTarget);
      }
    }).catch((_response) => {
      toastr.error(I18n.t('views.application.something_went_wrong'));
    });
  }

  destroy(event) {
    event.preventDefault();
    let componentsController = this;
    let currentTarget = event.currentTarget;

    const confirmation = confirm(I18n.t('views.buttons.are_you_sure'));
    if (!confirmation) { return; }

    this.stimulate('Components#destroy', event.currentTarget).then(() => {
      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
      else {
        toastr.success(this.t('controllers.notices.deleted'));
        componentsController.refreshComponentsTree(currentTarget);
      }
    });
  }

  refreshComponentsTree(target) {
    let parentId = target.dataset.parentItemId;
    let roots = document.querySelectorAll(`div.refresh_components_tree_hook.component_tree_${parentId}`);
    let uuids = [];
    roots.forEach(root => { uuids.push(root.id); })
    this.stimulate(
      'Components#refresh_components_tree', target, ...[{ 'components_tree_ids': uuids }]
    );
  }

  validationErrorMessage() {
    return $('#reflex_error_message').val();
  }

  displayErrorMessage() {
    let errorMessage = this.validationErrorMessage();
    toastr.error(errorMessage);
    $('#reflex_error_message').removeAttr('value');
  }

  updateAuthenticityToken(formId) {
    let customerForm = document.getElementById(formId);
    let authenticityToken = customerForm.querySelector("input[name='authenticity_token']");
    authenticityToken.value = this.csrfToken();
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }
}
