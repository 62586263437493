import ApplicationController from './application_controller';

export default class WarehouseDocumentItemsController extends ApplicationController {
  static targets = [
    'warehouseDocumentType', 'itemSelect', 'previousWarehouseSelect', 'warehouseSelect',
    'itemPreviousWarehouseQuantityLabel', 'itemWarehouseQuantityLabel'
  ];
  static values = {
    defaultItemPreviousWarehouseQuantityLabelId: {
      type: String,
      default: 'item_previous_warehouse_quantity_warehouse_document_item'
    },
    defaultItemWarehouseQuantityLabelId: {
      type: String,
      default: 'item_warehouse_quantity_warehouse_document_item'
    }
  }

  afterReflex() { }

  connect() {
    super.connect();
    if (this.warehouseDocumentTypeTarget.value == 'PmDocument') {
      this.setIdToPreviousQuantityLabel();
    }
    this.setIdToQuantityLabel();
    this.subscribeHandlers();
  }

  subscribeHandlers() {
    if (this.warehouseDocumentTypeTarget.value == 'PmDocument') {
      this.subscribeItemSelectTargetChange();
      this.subscribePreviousWarehouseSelectTargetChange();
    }
    this.subscribeWarehouseSelectTargetChange();
    this.refreshQuantityLabel(
      this.warehouseSelectTarget.value, this.itemWarehouseQuantityLabelTarget.id
    );
  }

  subscribeItemSelectTargetChange() {
    window.$(this.itemSelectTarget).on('change', () => {
      this.refreshQuantityLabel(
        this.previousWarehouseSelectTarget.value, this.itemPreviousWarehouseQuantityLabelTarget.id
      );
      this.setDefaultPreviousWarehouse();
      this.clearWarehouseSelect();
    });
  }

  subscribePreviousWarehouseSelectTargetChange() {
    window.$(this.previousWarehouseSelectTarget).on('change', () => {
      this.refreshQuantityLabel(
        this.previousWarehouseSelectTarget.value, this.itemPreviousWarehouseQuantityLabelTarget.id
      );
    });
  }

  subscribeWarehouseSelectTargetChange() {
    window.$(this.warehouseSelectTarget).on('change', () => {
      this.refreshQuantityLabel(
        this.warehouseSelectTarget.value, this.itemWarehouseQuantityLabelTarget.id
      );
    });
  }

  refreshQuantityLabel(warehouseId, quantityLabelId) {
    let itemId = this.itemSelectTarget.value;
    if (!itemId || !warehouseId)
      return;

    this.stimulate('Warehouses#item_quantity', warehouseId, itemId, quantityLabelId);
  }

  setIdToPreviousQuantityLabel() {
    if (this.itemPreviousWarehouseQuantityLabelTarget.id != this.defaultItemPreviousWarehouseQuantityLabelIdValue)
      return;

    let uniqueId = this.previousWarehouseSelectTarget.id.replace(/[^0-9]/g, '');
    uniqueId = `${this.defaultItemPreviousWarehouseQuantityLabelIdValue}_${uniqueId}`;
    this.itemPreviousWarehouseQuantityLabelTarget.setAttribute('id', uniqueId);
  }

  setIdToQuantityLabel() {
    if (this.itemWarehouseQuantityLabelTarget.id != this.defaultItemWarehouseQuantityLabelIdValue)
      return;

    let uniqueId = this.warehouseSelectTarget.id.replace(/[^0-9]/g, '');
    uniqueId = `${this.defaultItemWarehouseQuantityLabelIdValue}_${uniqueId}`;
    this.itemWarehouseQuantityLabelTarget.setAttribute('id', uniqueId);
  }

  setDefaultPreviousWarehouse() {
    window.$(this.previousWarehouseSelectTarget)
          .val(this.previousWarehouseSelectTarget.dataset.defaultWarehouseId)
          .trigger('change');
  }

  clearWarehouseSelect() {
    window.$(this.warehouseSelectTarget).val('').trigger('change');
    this.itemWarehouseQuantityLabelTarget.innerHTML = "";
  }
}
