import toastr from 'toastr/toastr';
import ApplicationController from './application_controller';
import { translationHelper } from "./mixins/translation_helper";

export default class extends ApplicationController {
  afterReflex() { }

  connect() {
    super.connect();
    translationHelper(this);

    let calendarController = this;

    $('#indexDurationModal').on('show.bs.modal', (event) => {
      toastr.clear();
      calendarController.fetchIndex(event.relatedTarget);
    })

    $('#showDurationModal').on('show.bs.modal', (event) => {
      toastr.clear();
      calendarController.fetchDetails(event.relatedTarget);
    })

    $('#newDurationModal').on('show.bs.modal', (event) => {
      toastr.clear();
      this.stimulate('Worktime::Calendar#new', event.relatedTarget)
    })

    $('#editDurationModal').on('show.bs.modal', (event) => {
      toastr.clear();
      this.stimulate('Worktime::Calendar#edit', event.relatedTarget)
    })
  }

  create(event) {
    this.stimulate('Worktime::Calendar#create', event.target).then(() => {
      $('#newDurationModal').modal('hide');
      this.reloadDaysEntries();
      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
      else {
        toastr.success(this.t('controllers.notices.created'));
        if ($('#duration_warning_message').val()) {
          alert($('#duration_warning_message').val());
          $('#duration_warning_message').removeAttr('value');
        }
      }
    }).catch((_response) => {
      toastr.error(I18n.t('views.application.something_went_wrong'));
    });
  }

  update(event) {
    let calendarController = this;

    this.stimulate('Worktime::Calendar#update', event.target).then(() => {
      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
      else {
        const worktimeDurationsIds = $(event.target).data('worktime-duration-ids');
        if (!worktimeDurationsIds.length) {
          calendarController.fetchDetails(event.target);
        }
        else {
          calendarController.fetchIndex(event.target);
        }
        $('#editDurationModal').modal('hide');
        this.reloadDaysEntries();
        toastr.success(this.t('controllers.notices.saved'));
      }
    }).catch((_response) => {
      toastr.error(I18n.t('views.application.something_went_wrong'));
    });
  }

  destroy(event) {
    event.preventDefault();

    const confirmation = confirm(I18n.t('views.buttons.are_you_sure'));
    if (!confirmation) { return; }

    let calendarController = this;
    this.stimulate('Worktime::Calendar#destroy', event.target).then(() => {
      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
      else {
        const worktimeDurationsIds = $(event.target).data('worktime-duration-ids');
        if (worktimeDurationsIds == undefined || !worktimeDurationsIds.length) {
          $('#showDurationModal').modal('hide');
        }
        else if (worktimeDurationsIds.length == 1) {
          $('#indexDurationModal').modal('hide');
        }
        else {
          calendarController.fetchIndex(event.target);
        }
        this.reloadDaysEntries();
        toastr.success(this.t('controllers.notices.deleted'));
      }
    });
  }

  fetchIndex(target) {
    this.stimulate('Worktime::CalendarReflex#index', target).then(() => {
      $('#worktime_duration_index').find($('[data-toggle="tooltip"]')).tooltip();
    });
  }

  fetchDetails(target) {
    this.stimulate('Worktime::CalendarReflex#show', target).then(() => {
      $('#worktime_duration_details').find($('[data-toggle="tooltip"]')).tooltip();
    });
  }

  reloadDaysEntries() {
    $('.day').mouseover(function() {$(this).find('.day-actions').show()});
    $('.day').mouseout(function() {$(this).find('.day-actions').hide()});
    $('.entry').mouseover(function() {$(this).find('.entry-actions').show()});
    $('.entry').mouseout(function() {$(this).find('.entry-actions').hide()});
  }

  validationErrorMessage() {
    return $('#reflex_error_message').val();
  }

  displayErrorMessage() {
    let errorMessage = this.validationErrorMessage();
    toastr.error(errorMessage);
    $('#reflex_error_message').removeAttr('value');
  }
}
