import toastr from 'toastr/toastr';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  afterReflex() { }

  connect() {
    super.connect();
  }

  findByNip(event) {
    let nipNumber = document.getElementById('nip_number').value;
    if (nipNumber.length == 0) {
      alert('Wprowadź NIP')
      $('#nip_number').focus();
      return
    }
    this.stimulate('Customers#find_by_nip', event.target, ...[{ 'nip_number': nipNumber }]).then(() => {
      this.updateAuthenticityToken();

      if (this.validationErrorMessage()) {
        this.displayErrorMessage();
      }
    }).catch((_response) => {
      toastr.error(I18n.t('views.application.something_went_wrong'));
    });
  }

  renderEmptyForm() {
    this.stimulate('Customers#render_empty_form').then(() => {
      this.updateAuthenticityToken();
    });
  }

  updateAuthenticityToken() {
    let customerForm = document.getElementById('customer-form');
    let authenticityToken = customerForm.querySelector("input[name='authenticity_token']");
    authenticityToken.value = this.csrfToken();
  }

  validationErrorMessage() {
    return $('#reflex_error_message').val();
  }

  displayErrorMessage() {
    let errorMessage = this.validationErrorMessage();
    toastr.error(errorMessage);
    $('#reflex_error_message').removeAttr('value');
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }
}
