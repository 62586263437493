function handleChangedItem(changedItem) {
  let quantityField = changedItem.closest('.row').find('input#quantity');
  let unitAddon = changedItem.closest('.row').find('.component-unit');
  let productUnit = changedItem.find(':selected').data('unit')
  unitAddon.html(productUnit)
  let quantityStep = changedItem.find(':selected').data('quantity-step')
  quantityField.attr('step', quantityStep);
}

export {
  handleChangedItem
}
