import * as Handlers from "../../packs/views/shared/items/components/handlers.js";

export const reflexableSelectsHelper = controller => {
  Object.assign(controller, {
    initReflexableSelects() {
      window.$('.stimulus-reflexable-select2').select2({
        placeholder: '',
        allowClear: true,
        language: 'pl'
      }).on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      })
    },
    initNonReflexableSelects() {
      window.$('.stimulus-non-reflexable-select2').select2({
        placeholder: '',
        allowClear: true,
        language: 'pl'
      })
    },
    handleChangedItem() {
      window.$('.stimulus-reflexable-select2').select2({
        placeholder: '',
        allowClear: true,
        language: 'pl',
        width: 'inherit'
      }).on('change', function() {
        Handlers.handleChangedItem($(this));
      })
    }
  });
};
