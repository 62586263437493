import ApplicationController from './application_controller';
import { reflexableSelectsHelper } from './mixins/reflexable_selects_helper';

export default class extends ApplicationController {
  afterReflex() {
    this.initReflexableSelects();
    this.initNonReflexableSelects();
  }

  connect() {
    super.connect();

    reflexableSelectsHelper(this);

    this.initReflexableSelects();
    this.initNonReflexableSelects();
  }
}
