import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['modalRoot', 'modalForm', 'modalFormErrors'];

  afterReflex() { }

  connect() {
    super.connect();
  }

  closeModal() {
    $(this.modalRootTarget).modal('hide');
  }

  cleanModal() {
    this.modalFormTarget.reset();
    this.modalFormErrorsTarget.innerHTML = '';
  }
}
