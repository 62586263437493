import toastr from 'toastr/toastr';
import { get } from '@rails/request.js';
import 'qs';
import ApplicationController from './application_controller';
import { translationHelper } from "./mixins/translation_helper";

export default class extends ApplicationController {
  static targets = ['selectCategoryControlId', 'latestCreatedCategoryId', 'categoryItemType'];
  static outlets = ['modals'];

  afterReflex() { }

  connect() {
    super.connect();
    translationHelper(this);
  }

  newCategoryFormSubmitted(event) {
    let response = event.detail.fetchResponse.response;
    if (response.status == 200) {
      this.getCategories().then((response) => {
        let categorySelectControl = $(`#${this.selectCategoryControlIdTarget.value}`);
        $(categorySelectControl).select2().empty();
        $(categorySelectControl).select2({
          data: response['categories'],
          placeholder: '',
          allowClear: true,
          language: 'pl',
          width: 'inherit'
        });
        $(categorySelectControl).val(this.latestCreatedCategoryIdTarget.value).trigger('change');
        this.modalsOutlet.closeModal();
        this.modalsOutlet.cleanModal();
        toastr.success(this.t('controllers.notices.created'));
      });
    }
  }

  async getCategories() {
    let params = qs.stringify({ q: { item_type_eq: this.categoryItemTypeTarget.value } });
    const response = await get(`/api/v0/categories?${params}`, { contentType: 'application/json' });
    if (response.ok) {
      const categories = await response.json
      return categories;
    }
  }
}
