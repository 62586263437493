// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("underscore")
require("qs")

import $ from 'jquery'
import _ from 'underscore'

// JS
import "bootstrap"
import "inputmask"
import "vue-inputmask"
import "cocoon"
import "lightgallery"
import "lg-thumbnail"
import "select2"
import "select2/dist/js/i18n/pl.js"
import "@chenfengyuan/datepicker"
import "@chenfengyuan/datepicker/i18n/datepicker.pl-PL.js";
import ApexCharts from 'apexcharts'
import "./main.js"
import "underscore"
import { i18n } from "../config/i18n"
import "metismenu"
import PerfectScrollbar from "perfect-scrollbar"
import '../packs/shared/toastr.js'
import "./utilities/service_worker.js"
import 'block-ui'
import 'v-tooltip'
import "onscan.js/onscan.min.js";
import onScan from 'onscan.js';
import Sortable from 'sortablejs';
import "@hotwired/turbo-rails"
import qs from 'qs';

// CSS
import "../packs/stylesheets/application"
import "lightgallery/dist/css/lightgallery.min.css";
import "select2/dist/css/select2.min.css";
import "@chenfengyuan/datepicker/dist/datepicker.min.css";
import "metismenu/dist/metisMenu.css"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import "@fortawesome/fontawesome-free/css/all.css"

window.ApexCharts = ApexCharts;
window.jQuery = $;
window.$ = $;
window._ = _;

global.I18n = i18n;

window.onScan = onScan;
window.Sortable = Sortable;
window.qs = qs;

Turbo.session.drive = false

import "controllers"
