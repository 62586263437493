import ApplicationController from './application_controller';

export default class extends ApplicationController {
  afterReflex() { }

  connect() {
    super.connect();
  }

  buildOperationsList(event) {
    this.stimulate('OperationsTree#build_operations_list', event.currentTarget)
  }

  updateAuthenticityToken(event) {
    let formId = `register_worktime_line_item_${event.currentTarget.dataset.lineItemId}` +
                 `_operation_${event.currentTarget.dataset.operationId}`;
    let registerWorktimeForm = document.getElementById(formId);
    let authenticityToken = registerWorktimeForm.querySelector("input[name='authenticity_token']");
    authenticityToken.value = this.csrfToken();
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }
}
